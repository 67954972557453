import {
  AccountBoxOutlined,
  ElectricMeterOutlined,
  KingBedOutlined,
  KitchenOutlined,
  NetworkWifiOutlined,
  PoolOutlined,
  ShowerOutlined,
  TvOutlined,
} from "@mui/icons-material";

export const NAVBAR_LINKS = [
  { item: "accomodation" },
  { item: "about" },
  { item: "baga beach" },
  { item: "blog", url: "https://souzalopescomforts.blogspot.com/" },
];

export const MAIN1_CONTENT = [
  "Clean & Comfortable Deluxe Rooms",
  "Large sized swimming pool",
  "Designer bathrooms",
  "Splendid exterior views of the Baga hillside",
  "Ample parking space",
  "Experience true Goan Hospitality",
];

export const FOOTER_LINKS = {
  general: [
    { item: "about", path: "/" },
    { item: "around us", path: "/" },
    { item: "baga beach", path: "/" },
  ],
  accomodation: [
    { item: "features", path: "/accomodation/#" },
    { item: "our standards", path: "/accomodation/#" },
    { item: "swimming pool", path: "/accomodation/#" },
    { item: "rules", path: "/accomodation/#" },
  ],
  help: [{ item: "contact us", path: "/" }, { item: "google form" }],
  socials: [
    { item: "instagram", url: "https://www.instagram.com/souzalopescomforts/" },
    { item: "youtube", url: "https://www.instagram.com/souzalopescomforts/" },
    { item: "blogger", url: "https://souzalopescomforts.blogspot.com/" },
  ],
};

export const LANDING_CTA = {
  airbnb: "",
  googleMaps: "https://goo.gl/maps/t23oqeUAyNf9EHSr5",
};

export const ROOM_FEATURES = [
  {
    title: "King sized beds",
    icon: <KingBedOutlined sx={{ fontSize: "56px" }} />,
  },
  {
    title: "Hot and cold running water",
    icon: <ShowerOutlined sx={{ fontSize: "48px" }} />,
  },
  {
    title: "Free WiFi",
    icon: <NetworkWifiOutlined sx={{ fontSize: "48px" }} />,
  },
  {
    title: "Television",
    icon: <TvOutlined sx={{ fontSize: "48px" }} />,
  },
  {
    title: "Free use of swimming pool",
    icon: <PoolOutlined sx={{ fontSize: "48px" }} />,
  },
  {
    title: "Inverter power backup",
    icon: <ElectricMeterOutlined sx={{ fontSize: "48px" }} />,
  },
  {
    title: "24h reception office",
    icon: <AccountBoxOutlined sx={{ fontSize: "48px" }} />,
  },
  {
    title: "Mini fridge in rooms",
    icon: <KitchenOutlined sx={{ fontSize: "48px" }} />,
  },
];

export const SOCIAL_LINKS = [
  {
    platform: "instagram",
    icon: "/images/socials/instagram-logo.svg",
    url: "https://www.instagram.com/souzalopescomforts/",
  },
  {
    platform: "youtube",
    icon: "/images/socials/youtube-logo.svg",
    url: "https://www.youtube.com/channel/UCux8dXhYJ1EwQY5h_IZeKRg",
  },
  {
    platform: "blogger",
    icon: "/images/socials/blogger-logo.svg",
    url: "https://souzalopescomforts.blogspot.com/",
  },
];

export const AROUND_US = {
  title: "Attractions around Sourza Lopes Comforts",
  main: [
    {
      title: "Baga Watersports",
      desc: "Don't miss the 5-in-1 water sports experience while you're in Baga, North Goa with family and friends!  Experience multiple adventurous activities such as parasailing, jet - ski, banana boat ride, bumper boat ride, and speed boat ride. Get complete understanding, basic skills, and safety methods from a qualified and certified expert. Don't burden yourself about bringing any tools as all the necessary equipment will be provided on-the-spot.",
      image: "/images/location/around-us/1.jpg",
    },
    {
      title: "Goa Snow Park",
      desc: "Visit Goa's first Snow Park and enjoy your preferred beverages in a unique ice glass and have fun with your family and friends. Play in the snow ground or enjoy the beats of the DJ’s songs with laser lights and a hypnotic thunder effect. Experience an ice cold atmosphere in the midst of the sunny and humid subtropical coastal land of Goa. Participate in numerous snow events such as Slide on Ice, Snow Volleyball, Sledging Car, Ice Bar, etc with your loved ones. Make some memories, take some stories, and some unforgettable pictures as a souvenir in the Ice Igloo and Ice Sculptures section available in the Baga Snow Park.",
      image: "/images/location/around-us/2.jpg",
    },
    {
      title: "Anjuna Flea Market",
      desc: "Goa's one and only International Flea Market is located in the beach village of Anjuna. Every Wednesday, like clockwork people come from all over India as well as the world to shop here. There is an enormous assortment of items for sale, and the place has a captivating atmosphere in its setting amid the palm trees and bang, besides the shore of Anjuna beach. It is an unforgettable experience. The flea market starts at 8 am and closes at 6 pm. It is busiest in the early afternoon. Hawkers, peddlers and vendors from Goa, India and even around the world come here to sell their items and goods. You can get all categories of clothes, Nepali as well as Tibetan goods, embroidered items, incense, a good selection of jewelry, semiprecious stones, crystals, fancy knives, handicrafts, hammocks, bedspreads, and other items at the flea market. There are also several people who sell the latest techno tapes.",
      image: "/images/location/around-us/3.jpg",
    },
    {
      title: "Splashdown Water Amusement Park",
      desc: "Splashdown is Goa's only and biggest Water amusement Park.  The Facility has 5 separate pools, an assortment of slides, flumes and other stimulating features. There is something to do for all ages, the mild and the strong of heart. Relive the child in you as you plunge down the water slides. The CDC has declared that Covid-19 Virus is not spread through water in the pools as chlorine in the water inactivates the virus. Goa Wax Museum Celebrity Wax Statutes are exhibited at Benz Celebrity Wax Museum, Goa. 9D Cinema is the second attraction at Benz Celebrity Wax Museum, Goa. Dashing Cars Game available for children.",
      image: "/images/location/around-us/4.png",
    },
    {
      title: "Calangute Beach",
      desc: "If you love crowded beaches full of tourists, then Calangute is the beach for you. Considered as Queen of North Goa beaches mainly by the flocking Indian tourists. Plenty of beach shacks, lots of multi-cuisine restaurants all around. All types of Watersports activities sold on this beach. Calangute beach is the neighboring beach positioned on the left hand side of Baga beach. Best way for guests staying at Souza Lopes Comforts is to walk directly on the beach from Baga to Calangute beach.",
      image: "/images/location/around-us/5.jpg",
    },
    {
      title: "Anjuna Beach",
      desc: "Anjuna beach is a beach village in the North of Goa. It is one of the twelve Brahmin comunidades of Bardez district. It was a famous hippy destination  during the sixties and seventies. Hence it is called the hippy capital of Goa. Anjuna is known throughout North Goa and to travelers from all over the world for its flea market at the Beach (Wednesdays), where one can find anything from Indian souvenirs to Trance music. Apart from all this, Anjuna is the birth place of many well-known musicians, medical doctors, and clergy. One such music group is the Electronic Dance Music (EDM) trio Above & Beyond.",
      image: "/images/location/around-us/6.jpg",
    },
    {
      title: "Baga Area Nightlife",
      desc: "Baga Beach area is quite renowned for its commercial club scene and nightlife. The well-known Tito’s Lane has many bars, lounges, and clubs like Club Tito, Cafe Mambo, Cape Town Cafe, Cocktails and Dreams, and Cavala that contribute to keeping the nightlife in Baga Beach, one of the best in Goa. Some Popular Bars, Night Clubs & Restaurants in Baga Beach are Seby Bar And Restaurant, Black Sheep Bistro, Bora Bora Café, La Musica, Mango Tree, Club Cubana, Jamies Restaurant, Royal Relish, J&A's Little Italy to name a few.",
      image: "/images/location/around-us/7.png",
    },
    {
      title: "Goa Tibetan Markets",
      desc: "Located besides the Baga Calangute road, these Tibetan exhibitions might look like a temporary make shift market for Tibetan entrepreneurs. Both the markets are situated along the Baga – Calangute road. While one is right in the beginning of road opposite to Macdonald’s, the other one is further ahead on the road, right next to Club Tao. The place is flooding with toe rings, neckpieces, rings and even beads and stone jewelry, oxidized jewelry, pearl necklace, embossed as well as carved singing bowls etc.",
      image: "/images/location/around-us/8.jpg",
    },
    {
      title: "Club Tito’s Lane at Baga",
      desc: "The Tito’s lane is a famous lane in Calangute – Baga area in North Goa. It is known for its various restaurants and nightclubs. However, the most famous spot is Tito’s pub.",
      image: "/images/location/around-us/9.jpg",
    },
    {
      title: "Club Tito’s at Baga",
      desc: "Opened in 1971, Tito’s is one of the most famous and best party clubs in Goa. Each day, there is a different night over here. Tito’s Goa is essentially a courtyard containing of an open-air restaurant, a concert space, and the nightclubs – Club Tito’s.  Electrifying lights, loud rhythmic music, and a thundering dance floor. With world-class music DJs, live acts and international dance performers there is a celebration every day here.  It gives you the best party vibes with a big dance floor and amazing music to experience the incredible entertainment of Goa.  There is a well-designed open-air restaurant too, The Tito’s restaurant.  It is one of the oldest and most popular nightclubs in Goa & ladies can walk in for free.",
      image: "/images/location/around-us/10.png",
    },
    {
      title: "Britto’s Seafood Restaurant",
      desc: "Our immediate neighbor is the World Famous Seafood restaurant of Britto’s. It needs not much of an introduction. Spacious seaside restaurant with an peppy vibe, well-known for seafood & desserts. This Baga beach restaurant is a foodie’s delight. A not to be missed dining outlet, that is just a few feet walking distance from the rooms of Souza Lopes Comforts hotel in Baga. Stay at Souza Lopes Comforts, Dine at Britto’s.",
      image: "/images/location/around-us/11.jpg",
    },
    {
      title: "Blue Whale Water Park, Baga.",
      desc: "This new Water Park in Baga is to be found just off the main Baga -Arpora road. The Blue Whale Water Park is positioned into a hill. There are 5 big slides and a children friendly area with lots of small slides, a Water Pool with a highlight of a Giant Whale fish that fills it with water then drops its water contents on to you. Worth a half - day’s visit while staying at Souza Lopes Comforts Hotel in Baga.",
      image: "/images/location/around-us/12.jpg",
    },
    {
      title: "Atlantis Watersports at Baga & Calangute beaches",
      desc: "Atlantis Watersports was started in 1993. They offer a wide range of watersports in Goa. Fun and thrilling activities like Scuba Diving, Fly boarding, Bungee Jumping, Parasailing and Jet Ski. They offer unmatched experiences for every type of traveller, both international and domestic tourists, as well as corporate bookings.",
      image: "/images/location/around-us/13.png",
    },
    {
      title: "Houseboat on Baga River",
      desc: "Houseboat on Baga River will be the most unforgettable experience of your holiday in Goa. A Goan Wooden House Boat all to yourself to relish the Goan Baga River. Watch the traditional fishermen in their dugout canoes from your boat deck as you sip your favored drink. Watch the butterflies frolicking or the Kingfisher bird diving for fish. Enjoy the sights and sounds of the village from the Riverside. The centuries old temples and churches dot the river giving you a glimpse of history. If you love fishing, we drop anchor in midstream and lure the fish. After a splendid day, you can be back to Baga Beach just in time to catch a glimpse of the soul stirring sunset on the horizons of the Arabian Sea. Baga Houseboat Tours offers a selection of house boat packages – sunrise trip, sunset trip, backwater tours, fishing trip. The most romantic choice is the overnight House Boat trip, with the Heavenly environment of a sparkling night, the gentle wind and the meandering river for company.",
      image: "/images/location/around-us/14.jpg",
    },
  ],
};

export const BAGA_BEACH = {
  title:
    "Beautifully sandwiched between the two beaches of Anjuna on the right and Calangute beach on the left",
  image: "/images/location/baga-beach/1.jpg",
  desc: "Baga beach is a coastal community in the northern part of Goa. The close neighbor of Baga beach is the world renowned Calangute beach. The beach of Baga lies in the extreme corner. One can start a Beach Walk at the foot of the Aguada Hill, walking first along the 5 Star Hotels stretch of Sinquerim beach, then entering the Candolim beach area and continuing into the Queen of North Goa beaches i.e. Calangute beach and finally culminating the beach walking exercise at the Baga beach.. Originally Baga beach was only a quiet fishing settlement. But with the entry of foreign tourists flocking to Goa, the entire fishing village converted itself into a tourist village with plenty of fine dining restaurants, dozens of beach shacks, choice of discotheques and nightclubs too. Souza Lopes Comforts Holiday Home is just 02 Minutes walking distance to this popular beach.",
  main: [
    {
      title: "The Meandering Baga River",
      desc: "By tradition and locally in Goa, the Baga River is also called as Riviera De Goa. This narrow rivulet is only 10 kms long and begins somewhere in the dense forests of Bardez and Assagao. Previously there used to exist a very interesting Box Bridge that has now been replaced by the most modern bridge of today. This Baga Bridge divides the agricultural fields on one side and the large scale commercial tourist developments on the other north side. The Baga River flows directly into the Arabian Sea. This river of Baga gets colorfully dressed during the Sangodd festival which is a traditional boat festival held in honor of Saint John the Baptist. Souza Lopes Comforts is located just a few minutes walking distance from the bridge and the river.",
      image: "/images/location/baga-beach/2.jpg",
    },
    {
      title: "Baga Estuary",
      desc: "The inlet of Baga is where the sweet twisting and winding waters of the Baga River meets and kisses the saline (salty) waters of the Arabian Sea for the first time. The Baga Estuary is a Tidal estuary, meaning the water level at the meeting point of the River and Sea rises and falls with the receding tide of the high seas. During high tide time one has to use Baga Bridge to cross the river. At low tide, one can walk barefoot and cross the same rivulet. Souza Lopes Comforts is bang opposite this Baga Estuary.",
      image: "/images/location/baga-beach/3.jpg",
    },
    {
      title: "About Baga Hillock",
      desc: "On setting foot on Baga beach, one cannot fail to notice the protruding hillock that enters into the Arabian Sea. This hill of Baga is free from any major tourist development and the only structure that exists from a long time ago is the Xavier Retreat House Built by Fr. Le Tellier after making fervent appeals, coupled with public prayers and penance to convince Prof. Christovao Egypsy, who was the landlord and title holder of the property, to donate the land for this social purpose. The Xavier Retreat House positioned on the very picturesque peninsular end of the Baga beach shore was constructed by Fr. Adrian Le Tellier S.J., a Belgian Jesuit belonging to the Province of Kolkata. In response to Fr. Le Tellier's appeals, parishioners willingly carried a stone each time they came up to attend daily mass. By 1951, the Retreat House stood complete - a compliment to the skills and the materials used at that time, with an aura of prayer surrounding the place - indeed, a true labor of love and participation. Cross the Baga River by the bridge and walk 10 minutes uphill to reach this Baga hillock retreat. It is a mega visual treat for the eyes. One can see all the 4 beaches of Baga to Sinquerim in a bird’s eye view from Baga Hillock. Your Souza Lopes Comforts is very close by from this amazing place.",
      image: "/images/location/baga-beach/4.jpg",
    },
    {
      title: "Baga boat ride to Anjuna Flea Market",
      desc: "Only on every Wednesday during the tourist season, that is from October to May, the fisher folk of Baga convert their fishing boats into passenger boats to ferry Indian and Foreign tourists who want to directly reach the Anjuna Flea Market which is organized near the shore-front of Anjuna beach. The boat ride begins from the Baga side of the Baga Hillock and moves in a semi-circle over the Arabian Sea and within 15 minutes one will find oneself approaching the shores of Anjuna beach on the other side of Anjuna Hillock. This wonderful joy ride on the boat is the best short cut to reach from Baga to Anjuna in a few minutes. Get off the wooden Goan boat and head directly to the Flea market to do some beach shopping for Indian souvenirs. Anjuna Flea Market is a major attraction to guests of Souza Lopes Comforts.",
      image: "/images/location/baga-beach/5.jpg",
    },
  ],
};

export const ABOUT_US = {
  title: "Let's tell you a little about ourselves",
  desc: `Goa - India’s paradise on Earth - is the place you want to be
  ….to unwind and enjoy the sand and surf. Whether you’re pining
  for a few days of respite in the midst of exploring the madness
  or craving for a two-week relaxed, beach holiday, ‘Souza Lopes
  Comforts’ offers just that! The land that this luxurious hotel
  is erected upon including the open plot in front of this edifice
  was once lush paddy fields belonging to Ana Rofina Souza. A
  century earlier, her husband purchased this agricultural holding
  for his better half and their darling princess, Flory. Flory and
  her troupe of 6 tilled the fields until around 25 years ago when
  she gladly acquiesced to the request of the Government of Goa
  and parted with a part of her land toward the development of the
  Baga Beach – an iconic feature of the Goan landscape.`,
  main: [
    {
      desc: `Flory and her husband Pascoal could best be described as a cup of
      “chao” in the middle of the monsoon – warm and comforting. Spurred
      by the vision of their parents, their children - Anthony, Wilfred,
      Floyd, Francis and Jacinta - embarked on the journey to transform
      this space into one where true Goan hospitality can be experienced
      and enjoyed. Besides this new venture, Anthony and Lorraine, run
      "The Indian Kitchen" which was a popular Indian food restaurant
      but ever since our father Pascoal passed away it has closed doors
      and now only operates as budget accommodation to international
      travellers.`,
      image: "/images/location/collage-bedroom.jpg",
    },
    {
      desc: `Wilfred and Jacinta have converted their ancestral house and have created luxury apartments for the luxury seeking tourist. Francis, Maggie and their children have made it comfortable for themselves in the Swiss Alps. Floyd, Gillian and their son have moved ahead and run the popular beach cafe called the "Love Shack" on the Calangute stretch of the beach and are content in spite of the cafe running only seasonal as permissions are only for 5 months of the year. To keep themselves busy, they’ve converted their home into a luxury affordable stay for tourists and is called "Casa Nemo".`,
      image: "/images/location/collage-washroom.jpg",
    },
  ],
};

export const ACCOMODATION = {
  title: "Serving the Goan hospitality since Portuguese times",
  desc: `Welcome to Souza Lopes Comforts your Goan “Home away from Home”
        at Baga beach near Calangute city in North Goa. Words of
        endearment that tourists leave behind in Goa; to many Goa is a
        “Piece of Paradise” or a “Slice of Heaven”. Holiday in Goa is a
        collection of “memories & stories” that last for a lifetime. The
        deluxe and luxurious modern hotel of Souza Lopes Comforts is
        built around idyllic and serene agricultural backgrounds, where
        you can feel the pulse of calm and comfort besides peace. True
        to their holiday property name, run & managed by a Goan Catholic
        family, they offer you the best in terms of hospitality,
        comforts and service. Souza Lopes Comforts is positioned in the
        heart of Tourist North Goa, surrounded by all the Party, Picnic
        and Popular tourist destinations. Souza Lopes Comforts is just 2
        minutes walking distance from the world famous Baga Beach of
        Goa.`,

  features: {
    title: "Our Facilities & Features",
    main: [
      {
        title: "King sized beds",
        icon: <KingBedOutlined sx={{ fontSize: "56px" }} />,
      },
      {
        title: "Hot and cold running water",
        icon: <ShowerOutlined sx={{ fontSize: "48px" }} />,
      },
      {
        title: "Free WiFi",
        icon: <NetworkWifiOutlined sx={{ fontSize: "48px" }} />,
      },
      {
        title: "Television",
        icon: <TvOutlined sx={{ fontSize: "48px" }} />,
      },
      {
        title: "Free use of swimming pool",
        icon: <PoolOutlined sx={{ fontSize: "48px" }} />,
      },
      {
        title: "Inverter power backup",
        icon: <ElectricMeterOutlined sx={{ fontSize: "48px" }} />,
      },
      {
        title: "24h reception office",
        icon: <AccountBoxOutlined sx={{ fontSize: "48px" }} />,
      },
      {
        title: "Mini fridge in rooms",
        icon: <KitchenOutlined sx={{ fontSize: "48px" }} />,
      },
    ],
  },
  pool: {
    main: [
      {
        title: "Collabrative swimming pool",
        desc: `The Souza Lopes family is a unique Joint Catholic family,
    which is a rarity in these modern days. As a Joint Catholic
    family all of their holiday properties are “Collectively &
    Jointly” managed and run as one Lopes family unit. Due to the
    family being united in business, they have COLLABORATED (work
    jointly on an activity or project) to offer their customers,
    patrons and guests staying at Souza Lopes Comforts, access and
    use the Swimming Pool facility in Indian Kitchen at Calangute
    in North Goa.`,
        image: "/images/location/swimming-pool-full.jpg",
      },
    ],
  },
  rules: {
    title: "Rules & regulations for guests",
    main: [
      `Please switch off the lights and fans when going out, or
          you might be charged extra.`,
      `Be aware that water is scarce in Goa and hence it is very
          essential that you close all water taps while going out and
          you may avoid washing of clothes in the rooms/bathrooms.`,
      `You may leave your Room Keys with the Receptionist, every
          alternate day as we clean rooms and change linen etc. on every
          alternate days only. If you feel insecure, you may request to
          clean your room during your presence. Room cleaning is a
          service that we provide at no extra cost, however a little
          show of gratitude to the attendant will go a long way.You may
          leave your Room Keys with the Receptionist, every alternate
          day as we clean rooms and change linen etc. on every alternate
          days only. If you feel insecure, you may request to clean your
          room during your presence. Room cleaning is a service that we
          provide at no extra cost, however a little show of gratitude
          to the attendant will go a long way.`,
      `Any breakages will have to be paid for besides the rent.`,
      `Rooms are provided with a fridge, minimum crockery, and
          you may buy provisions in the nearby department stores which
          will make your money’s run a little longer.`,
      `We do not provide toilet tissue paper; hence rooms are
          provided with water faucets.Use of toilet papers are
          discouraged and may be avoided.`,
      `For your safety and the safety of other tourist guests
          staying in this hotel accommodation, we advise you not to
          bring any visitors to the Souza Lopes Comforts Rooms.`,
      `You need to take utmost care of your
          belongings/possessions and in case of any loss/theft we will
          not be liable to compensate you; kindly note this.`,
      `We sincerely endeavour to provide you with the maximum
          comforts to make your stay with us a pleasant one. In the
          event of any unforeseen circumstances arising during your
          stay, do bear with us while we look for a solution to the
          issue.`,
      `We are proud that you will be our ambassadors of goodwill
        and we hope you pass on to others our experiences, good or bad
        of your stay with us.`,
    ],
  },
  standards: {
    title: "Our standards",
    desc: `The Souza Lopes family is a unique Joint Catholic family,
      which is a rarity in these modern days. As a Joint Catholic
      family all of their holiday properties are “Collectively &
      Jointly” managed and run as one Lopes family unit. Due to the
      family being united in business, they have COLLABORATED (work
      jointly on an activity or project) to offer their customers,
      patrons and guests staying at Souza Lopes Comforts, access and
      use the Swimming Pool facility in Indian Kitchen at Calangute
      in North Goa.`,
    main: [
      {
        title: "Soothing Design & Decor Rooms",
        desc: `Souza Lopes Rooms are designed and decorated with a touch of
          modernity & minimalism to provide you rooms that are Clean,
          Cozy & Comfortable. To give you that home feeling and at the
          same time to give you a feeling of luxurious holiday.`,
      },
      {
        title: "Closest to Baga Beach in North Goa",
        desc: `Souza Lopes Comforts Hotel is situated very close to the
          best Baga Beach & Calangute beach of North Goa. It takes
          only 02 Minutes of walking time to reach the Baga Beach by
          foot.`,
      },
      {
        title: "Enjoy True Goan Hospitality",
        desc: `Souza Lopes Comforts Hotel is owned & managed by the Goan
          Lopes family. This local Catholic Goan family is involved in
          the Goa Tourism & Hospitality Industry from ancient
          Portuguese times. So welcome aboard to true Goan hospitality
          from the heart.`,
      },
      {
        title: "Countryside Experience near Goa Beaches",
        desc: `Enjoy the Bliss & Serenity that Baga hill - side has to
          offer while living at the Souza Lopes Comforts Hotel.
          Experience the adventurous activities that Baga and
          Calangute beaches have to offer.`,
      },
    ],
  },
};

export const REVIEWS = [
  {
    name: "Person",
    designation: "Stayed x days",
    rating: 5,
    avatar: "/images/avatars/avatar.png",
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget`,
  },
  {
    name: "Person",
    designation: "Stayed x days",
    rating: 5,
    avatar: "/images/avatars/avatar.png",
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget`,
  },
  {
    name: "Person",
    designation: "Stayed x days",
    rating: 5,
    avatar: "/images/avatars/avatar.png",
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget`,
  },
  {
    name: "Person",
    designation: "Stayed x days",
    rating: 5,
    avatar: "/images/avatars/avatar.png",
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget`,
  },
];