import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @font-face {
  font-family: "CircularStd-Private";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/circular/CircularStd-Book.otf");
}

  @font-face {
    font-family: "CircularStd-Private";
    font-style: normal;
    font-weight: 500;
    src: url("/fonts/circular/CircularStd-Medium.otf");
  }

  @font-face {
    font-family: "CircularStd-Private";
    font-style: normal;
    font-weight: 600;
    src: url("/fonts/circular/CircularStd-Bold.otf");
  }

  @font-face {
    font-family: "CircularStd-Private";
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/circular/CircularStd-Black.otf");
  }

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background: #f2f3f5;
    overflow-x: hidden;
  }

  #root {
    position: relative;
    overflow-x: hidden;
  }

  /* Scrollbar */

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(162, 162, 167);
    border-radius: 100px;
  }

  .react-slideshow-container+ul.indicators .each-slideshow-indicator:before{
    background: white !important;
  }

  .highlight-image{
    box-shadow: 10px 10px 20px #00000020;
    border-radius: 15px ;
  }

  @keyframes pop {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }

  
}
`;
