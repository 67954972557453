import { Grid, Stack, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Navbar } from "../../components/Navbar";
import { Content } from "./Content";
import { Slideshow } from "./Slideshow";

export const Landing = ({ isMenuVisible, setIsMenuVisible }) => {
  const b1 = useMediaQuery("(max-width:1224px)");
  const b2 = useMediaQuery("(max-width:600px)");
  const b3 = useMediaQuery("(max-width:500px)");

  return (
    <Stack
      height={b1 ? "auto" : "100vh"}
      width="100%"
      justifyContent="center"
      alignitems="center"
      sx={{
        position: "relative",
        zIndex: 9,
        "&::before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "url(/images/bg.png) center",
          backgroundSize: "cover",
          filter: "brightness(0.8)",
          zIndex: -1,
        },
      }}
    >
      <Navbar
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
      <Box my={b1 ? 8 : 0} flex={1}>
        <Grid
          container
          width={b2 || b1 ? "90%" : "75%"}
          height="100%"
          m="auto"
          justifyContent="center"
          alignItems="center"
          direction={b1 ? "column" : "row"}
        >
          <Grid item md={6}>
            <Content b1={b1} b3={b3} />
          </Grid>
          <Grid item md={6}>
            <Slideshow b1={b1} b2={b2} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
