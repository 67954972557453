import { Stack, Typography } from "@mui/material";
import { Button } from "../../../components/Button";
import { LANDING_CTA } from "../../../constants";

export const Content = ({ b1, b3 }) => {
  return (
    <Stack
      spacing={2}
      my={4}
      width="100%"
      sx={{ textAlign: b1 ? "center" : "left" }}
    >
      <div data-aos="fade-up" data-aos-delay="0">
        <Typography variant={b3 ? "h3" : "h2"} color="white.main">
          Spacious & Comfortable
          <br /> Deluxe Rooms
        </Typography>
      </div>
      <div data-aos="fade-up" data-aos-delay="100">
        <Typography variant="h6" color="#ffffff90">
          Book a stay you’ll love, on your next visit to goa
        </Typography>
      </div>
      <div data-aos="fade-up" data-aos-delay="200">
        <Stack
          direction={b1 ? "column" : "row"}
          spacing={2}
          py={4}
          justifyContent={b1 ? "center" : "left"}
          alignItems={b1 ? "center" : "left"}
        >
          <Button
            variant="contained"
            color="white"
            sx={{
              width: b1 ? "100% !important" : "fit-content",
            }}
            startIcon={
              <img src="images/socials/airbnb-logo.svg" width="24" alt="" />
            }
            onClick={() => window.open(LANDING_CTA.airbnb)}
          >
            Book now
          </Button>
          <Button
            variant="outlined"
            color="white"
            sx={{
              width: b1 ? "100% !important" : "fit-content",
            }}
            startIcon={
              <img
                src="images/socials/google-maps-logo.svg"
                width="22"
                alt=""
              />
            }
            onClick={() => window.open(LANDING_CTA.googleMaps)}
          >
            See location
          </Button>
        </Stack>
      </div>
    </Stack>
  );
};
