import { Box } from "@mui/system";
import { Fade } from "react-slideshow-image";

export const Slideshow = ({ b1, b2 }) => {
  const images = [
    "images/location/front-view.png",
    "images/location/bedroom-view-1.png",
    "images/location/outside-full.jpg",
    "images/location/reception-full.jpg",
    "images/location/bedroom-full.jpg",
  ];

  const fadeProps = {
    indicators: b1 ? false : true,
    arrows: b1 ? false : true,
    duration: 2000
  };

  return (
    <div data-aos="zoom-in" data-aos-delay="300">
      <Box
        sx={{
          bgcolor: "#ffffff40",
          p: 1,
          borderRadius: 2,
          width: b1 || b2 ? "80vw" : "100%",
        }}
      >
        <Fade {...fadeProps}>
          {images.map((each, index) => (
            <div key={index} className="each-fade">
              <div>
                <img
                  style={{
                    objectFit: "cover",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                  src={each}
                  alt=""
                />
              </div>
            </div>
          ))}
        </Fade>
      </Box>
    </div>
  );
};
