import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { ContactForm } from "../sections/ContactForm";
import { CTABanner } from "../sections/CTABanner";
import { Footer } from "../sections/Footer";

export const ContactUs = () => {
  const b1 = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Box height={80}></Box>
      <Grid
        container
        width={b1 ? "90%" : "75%"}
        m="auto"
        my={4}
        justifyContent="center"
        alignItems="center"
        direction="row"
        maxWidth={1270}
      >
        <Grid item lg={5} width="100%">
          <Stack spacing={1}>
            <div data-aos="fade-up" data-aos-delay="100">
              <Typography
                variant="p"
                color="primary.main"
                sx={{ textTransform: "uppercase" }}
              >
                Contact us
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="300">
              <Typography
                variant={b1 ? "h4" : "h3"}
                sx={{
                  fontWeight: 500,
                }}
              >
                We're listening :)
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="500">
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "grey.main",
                }}
              >
                We’re here to help. Drop your suggestions
                <br />or queries and we'll get back to you asap
              </Typography>
            </div>
          </Stack>
        </Grid>
        <Grid item lg={7} width="100%">
          <ContactForm b1={b1} />
        </Grid>
      </Grid>
      <CTABanner />
      <Footer />
    </>
  );
};
