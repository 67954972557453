import { Grid, Stack, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Content } from "./Content";

export const Main3 = () => {
  const b1 = useMediaQuery("(max-width:1024px)");
  const b2 = useMediaQuery("(max-width:600px)");
  return (
    <Stack
      height={b1 ? "auto" : "90vh"}
      width={b2 ? "90%" : "75%"}
      pt={b1 ? 8 : 0}
      pb={12}
      m="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        alignItems={b1 ? "start" : "center"}
        direction={b1 ? "column-reverse" : "row"}
        rowSpacing={b1 ? 8 : 0}
        columnSpacing={!b1 ? 8 : 0}
      >
        <Grid item xs={6} sx={{ width: "100%" }}>
          <div data-aos="zoom-in" data-aos-delay="0">
            <Stack spacing={4}>
              <div data-aos="zoom-in" data-aos-delay="0">
                <img
                  src="/images/location/front-view.png"
                  width="100%"
                  className="highlight-image"
                  alt=""
                />
              </div>
              <Stack direction="row" spacing={4}>
                <Box flex={1}>
                  <div data-aos="zoom-in" data-aos-delay="300">
                    <img
                      src="/images/location/bedroom-square.png"
                      className="highlight-image"
                      width="100%"
                      alt=""
                    />
                  </div>
                </Box>
                <Box flex={1}>
                  <div data-aos="zoom-in" data-aos-delay="500">
                    <img
                      src="/images/location/reception-square.png"
                      className="highlight-image"
                      width="100%"
                      alt=""
                    />
                  </div>
                </Box>
              </Stack>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Content />
        </Grid>
      </Grid>
    </Stack>
  );
};
