import { Grid, Stack, useMediaQuery } from "@mui/material";
import { Content } from "./Content";

export const Main2 = () => {
  const b1 = useMediaQuery("(max-width:1024px)");
  const b2 = useMediaQuery("(max-width:600px)");

  return (
    <Stack
      height={b1 ? "auto" : "90vh"}
      width={b2 ? "90%" : "75%"}
      py={b1 ? 8 : 0}
      m="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        alignItems={b1 ? "start" : "center"}
        direction={b1 ? "column" : "row"}
        rowSpacing={b1 ? 8 : 0}
        columnSpacing={!b1 ? 8 : 0}
      >
        <Grid item xs={6}>
          <Content />
        </Grid>
        <Grid item xs={6} sx={{ width: "100%" }}>
          <div data-aos="zoom-in" data-aos-delay="0">
            <img src="/images/location/baga-beach.png" width="100%" alt="" />
          </div>
        </Grid>
      </Grid>
    </Stack>
  );
};
