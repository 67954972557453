import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { SocialLinks } from "./SocialLinks";

export const FooterBottom = ({ b2 }) => {
  return (
    <Stack
      direction={b2 ? "column" : "row"}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      spacing={2}
    >
      <Link to="/">
        <img src="/images/logo-black.svg" alt="" />
      </Link>
      <Stack
        direction={b2 ? "column" : "row"}
        alignItems={b2 ? "center" : ""}
        spacing={0.5}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          ©2022 Souza Lopez Comforts {b2 ? "" : "—"}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "grey.main", fontWeight: 500 }}
        >
          All Rights Reserved
        </Typography>
      </Stack>
      <SocialLinks />
    </Stack>
  );
};
