import { ThemeProvider } from "@mui/material";
import { GlobalStyles } from "./globalStyles";
import { theme } from "./theme";
import "react-slideshow-image/dist/styles.css";
import Home from "./pages/home";
import { Route, Routes } from "react-router-dom";
import Layout from "./pages/layout";
import { init, refresh } from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useState } from "react";
import About from "./pages/about";
import BagaBeach from "./pages/baga-beach";
import Accomodation from "./pages/accomodation";
import AroundUs from "./pages/around-us";
import { ContactUs } from "./pages/contact-us";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      window.scrollY === 0
        ? setUserHasScrolled(false)
        : setUserHasScrolled(true);
    };
    if (isMenuVisible) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  useEffect(() => {
    init({
      once: true,
      duration: 1000,
    });
    refresh();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              userHasScrolled={userHasScrolled}
              isMenuVisible={isMenuVisible}
              setIsMenuVisible={setIsMenuVisible}
            />
          }
        >
          <Route
            index
            element={
              <Home
                isMenuVisible={isMenuVisible}
                setIsMenuVisible={setIsMenuVisible}
              />
            }
          />
          <Route path="accomodation" element={<Accomodation />} />
          <Route path="about" element={<About />} />
          <Route path="around-us" element={<AroundUs />} />
          <Route path="baga-beach" element={<BagaBeach />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
