import { Box, Stack, useMediaQuery } from "@mui/material";
import { NAVBAR_LINKS } from "../../constants";
import { MenuLink } from "./MenuLink";

export const Menu = ({ setIsMenuVisible }) => {
  const b1 = useMediaQuery("(max-width:1132px)");
  return (
    b1 && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 80,
            background: "#f2f3f5",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 99,
            borderTop: "1px solid #ddd",
            animation: "pop ease 0.3s",
          }}
        >
          <Stack m="auto" width="90%" py={2}>
            {NAVBAR_LINKS.map(({ item, url }, idx) => (
              <MenuLink key={idx} url={url} setIsMenuVisible={setIsMenuVisible}>
                {item}
              </MenuLink>
            ))}
          </Stack>
        </Box>
      </>
    )
  );
};
