import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Footer } from "../sections/Footer";
import { CTABanner } from "../sections/CTABanner";
import { BAGA_BEACH } from "../constants";

const BagaBeach = () => {
  const b1 = useMediaQuery("(max-width:1024px)");
  const b2 = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Stack width={b2 ? "90%" : "75%"} m="auto" my={6} pt={10}>
        <Stack spacing={8}>
          <Stack spacing={2}>
            <div data-aos="fade-up" data-aos-delay="0">
              <Typography
                variant="p"
                color="primary.main"
                sx={{ textTransform: "uppercase" }}
              >
                Baga beach
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="100">
              <Typography variant={b2 ? "h4" : "h3"} sx={{ fontWeight: 500 }}>
                {BAGA_BEACH.title}
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="200">
              <Typography
                variant="p"
                color="grey.main"
                sx={{ fontWeight: 500 }}
              >
                {BAGA_BEACH.desc}
              </Typography>
            </div>
          </Stack>
          {BAGA_BEACH.main.map(({ title, desc, image }, idx) => (
            <Grid
              container
              alignItems={b1 ? "start" : "center"}
              direction={b1 ? "column" : idx % 2 === 0 ? "row" : "row-reverse"}
              gap={b1 ? 4 : 8}
              flexWrap="nowrap"
              py={b1 ? 2 : "calc(4vh + 40px)"}
              key={idx}
            >
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <div data-aos="fade-up" data-aos-delay="100">
                    <Typography
                      variant={b1 ? "h4" : "h3"}
                      color="black.main"
                      fontWeight={500}
                    >
                      {title}
                    </Typography>
                  </div>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Typography variant="p" color="grey.main">
                      {desc}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ width: "100%" }}>
                <div data-aos="zoom-in" data-aos-delay="0">
                  <img
                    src={image}
                    className="highlight-image"
                    width="100%"
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Stack>
      <CTABanner />
      <Footer />
    </>
  );
};

export default BagaBeach;
