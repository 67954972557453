import { Stack, Typography, useMediaQuery } from "@mui/material";

export const Quote = () => {
  const b1 = useMediaQuery("(max-width:600px)");

  return (
    <Stack
      spacing={2}
      width="100%"
      py={10}
      px={6}
      alignItems="center"
      justifyContent="center"
      bgcolor="white.main"
      sx={{ boxSizing: "border-box" }}
    >
      <img
        src="/images/logo-inline-black.svg"
        alt=""
        style={{ filter: "opacity(0.5)" }}
      />
      <Typography
        variant={b1 ? "h5" : "h4"}
        sx={{ textAlign: "center", width: b1 ? "90%" : "75%", fontWeight: 600 }}
      >
        “Life does not come with an instruction manual on how to live. But it
        surely comes with a Bountiful & Blessed place called Goa”
      </Typography>
    </Stack>
  );
};
