import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { ACCOMODATION } from "../constants";
import { Footer } from "../sections/Footer";
import { CTABanner } from "../sections/CTABanner";
import { Box } from "@mui/system";

const Accomodation = () => {
  const b1 = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Box height={80}></Box>
      <Stack width={b1 ? "90%" : "75%"} m="auto" my={6}>
        <Stack gap={8}>
          <Stack gap={2} width={b1 ? "100%" : "75%"}>
            <div data-aos="fade-up" data-aos-delay="0">
              <Typography
                variant="p"
                color="primary.main"
                sx={{ textTransform: "uppercase" }}
              >
                Accomodation
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="100">
              <Typography variant={b1 ? "h4" : "h3"} sx={{ fontWeight: 500 }}>
                {ACCOMODATION.title}
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="200">
              <Typography
                variant="p"
                color="grey.main"
                sx={{ fontWeight: 500 }}
              >
                {ACCOMODATION.desc}
              </Typography>
            </div>
          </Stack>
          <Box mt={-16} pt={16} id="features">
            <Stack gap={4}>
              <div data-aos="fade-up" data-aos-delay={300}>
                <Typography variant={b1 ? "h5" : "h4"} sx={{ fontWeight: 500 }}>
                  {ACCOMODATION.features.title}
                </Typography>
              </div>
              <Stack gap={4} direction="row" width="100%" flexWrap="wrap">
                {ACCOMODATION.features.main.map(({ title, icon }, idx) => (
                  <div key={idx} data-aos="fade-up" data-aos-delay={idx * 100}>
                    <Stack
                      key={idx}
                      direction="column"
                      borderRadius={6}
                      width={200}
                      sx={{ overflow: "hidden" }}
                    >
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        height={160}
                        bgcolor="white.main"
                      >
                        {icon}
                      </Stack>
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        height={70}
                        bgcolor="black.main"
                        p={1}
                      >
                        <Typography
                          variant="p"
                          color="white.main"
                          textAlign="center"
                        >
                          {title}
                        </Typography>
                      </Stack>
                    </Stack>
                  </div>
                ))}
              </Stack>
            </Stack>
          </Box>

          <Box mt={-16} pt={16} id="swimming-pool">
            {ACCOMODATION.pool.main.map(({ title, desc, image }, idx) => (
              <Grid
                container
                alignItems={b1 ? "start" : "center"}
                direction={
                  b1 ? "column" : idx % 2 === 0 ? "row" : "row-reverse"
                }
                gap={b1 ? 4 : 8}
                flexWrap="nowrap"
                py={b1 ? 2 : "calc(4vh + 40px)"}
                key={idx}
              >
                <Grid item xs={6}>
                  <Stack gap={2}>
                    <div data-aos="fade-up" data-aos-delay="100">
                      <Typography
                        variant={b1 ? "h5" : "h4"}
                        color="black.main"
                        fontWeight={500}
                      >
                        {title}
                      </Typography>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200">
                      <Typography variant="p" color="grey.main">
                        {desc}
                      </Typography>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={6} sx={{ width: "100%" }}>
                  <div data-aos="zoom-in" data-aos-delay="300">
                    <img
                      src={image}
                      className="highlight-image"
                      width="100%"
                      alt=""
                    />
                  </div>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Stack gap={4}>
            <Box mt={-16} pt={16} id="our-standards">
              <Stack gap={2}>
                <div data-aos="fade-up">
                  <Typography
                    variant={b1 ? "h5" : "h4"}
                    sx={{ fontWeight: 500 }}
                  >
                    {ACCOMODATION.standards.title}
                  </Typography>
                </div>
                <div data-aos="fade-up">
                  <Typography
                    variant="p"
                    color="grey.main"
                    sx={{ fontWeight: 500 }}
                  >
                    {ACCOMODATION.standards.desc}
                  </Typography>
                </div>
              </Stack>
            </Box>
            <Stack gap={4} p={4} borderRadius={4} bgcolor="white.main">
              {ACCOMODATION.standards.main.map(({ title, desc }, idx) => (
                <Stack gap={1} key={idx}>
                  <Typography
                    variant={b1 ? "p" : "h6"}
                    sx={{ fontWeight: 500 }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="p"
                    color="grey.main"
                    sx={{ fontWeight: 500 }}
                  >
                    {desc}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Box mt={-16} pt={16} id="rules">
            <Stack
              gap={4}
              p={6}
              borderRadius={4}
              bgcolor="black.main"
              sx={{ color: "white.main", boxSizing: "content-box" }}
            >
              <Typography variant={b1 ? "h5" : "h4"} sx={{ fontWeight: 500 }}>
                {ACCOMODATION.rules.title}
              </Typography>
              <Stack gap={2}>
                {ACCOMODATION.rules.main.map((elem, idx) => (
                  <Typography variant="p">
                    {idx + 1}. {elem}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <CTABanner />
      <Footer />
    </>
  );
};

export default Accomodation;
