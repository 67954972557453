import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const MenuLink = ({ url, children, setIsMenuVisible }) => {
  return url ? (
    <Box onClick={() => window.open(url)}>
      <Typography
        variant="h6"
        color="black.main"
        sx={{
          textTransform: "capitalize",
          py: 2,
          cursor: "pointer",
          fontWeight: 600,
        }}
      >
        {children.replace("-", " ")}
      </Typography>
    </Box>
  ) : (
    <Link
      to={"/" + children.replaceAll(" ", "-").toLowerCase()}
      style={{ textDecoration: "none" }}
      onClick={() => setIsMenuVisible(false)}
    >
      <Typography
        variant="h6"
        color="black.main"
        sx={{
          textTransform: "capitalize",
          py: 2,
          cursor: "pointer",
          fontWeight: 600,
        }}
      >
        {children.replace("-", " ")}
      </Typography>
    </Link>
  );
};
