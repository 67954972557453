import { Hotel } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export const Content = () => {
  return (
    <Stack spacing={2} width="fit-content">
      <div data-aos="zoom-in-right" data-aos-delay="0" data-aos-duration="700">
        <Stack
          justifyContent="center"
          alignItems="center"
          width="48px"
          height="48px"
          sx={{ border: "1px solid black", borderRadius: "10px" }}
        >
          <Hotel />
        </Stack>
      </div>
      <div data-aos="fade-up" data-aos-delay="100">
        <Typography variant="h3" color="black.main">
          Right place, Right beach, Right Comforts
        </Typography>
      </div>
      <div data-aos="fade-up" data-aos-delay="200">
        <Typography variant="p" color="grey.main">
          The Souza Lopes Comforts is a modern 2 Star Deluxe Hotel accommodation
          located just 02 minutes actual walking distance from the pristine
          sands of Baga beach, Goa. After a full day of sight-seeing and late
          night partying in North Goa, you would like to rest your happy “Soul &
          Soles” in Clean, Cozy and Comfortable rooms to let your hair down and
          relax.
        </Typography>
      </div>
    </Stack>
  );
};
