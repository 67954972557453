import {  Stack, TextField, Typography } from "@mui/material";
import { Button } from "../../components/Button";

export const ContactForm = ({ b1 }) => {
  return (
    <div data-aos="zoom-in" data-aos-delay="800">
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <Stack
          p={6}
          spacing={4}
          bgcolor="white.main"
          justifyContent="center"
          alignItems="center"
          borderRadius={2}
          width={b1 ? "100%" : "420px"}
          m="auto"
          my={4}
          sx={{
            boxShadow: "10px 10px 20px #eee",
            boxSizing: b1 ? "border-box" : "",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 500,
            }}
          >
            Get in touch
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{ width: "100%" }}
              label="First Name"
              name="first-name"
              variant="outlined"
              required
            />
            <TextField
              sx={{ width: "100%" }}
              label="Last Name"
              name="last-name"
              variant="outlined"
              required
            />
          </Stack>
          <TextField
            sx={{ width: "100%" }}
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            required
          />
          <TextField
            sx={{ width: "100%" }}
            label="Message"
            name="message"
            rows={4}
            variant="outlined"
            multiline
            required
          />
          <Button
            type="submit"
            color="black"
            variant="contained"
            sx={{ height: "56px !important", width: "100% !important" }}
          >
            Send message
          </Button>
        </Stack>
      </form>
    </div>
  );
};
