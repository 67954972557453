import { Box, Stack } from "@mui/material";
import { SOCIAL_LINKS } from "../../../../constants";

export const SocialLinks = () => {
  return (
    <Stack direction="row" spacing={2}>
      {SOCIAL_LINKS.map(({ platform, icon, url }, idx) => (
        <Box
          onClick={() => window.open(url)}
          key={idx}
          sx={{ cursor: "pointer" }}
        >
          <img src={icon} width={32} height={32} alt={platform} />
        </Box>
      ))}
    </Stack>
  );
};
