import { Stack, useMediaQuery } from "@mui/material";
import { FooterBottom } from "./FooterBottom";
import { FooterTop } from "./FooterTop";

export const Footer = () => {
  const b1 = useMediaQuery("(max-width:600px)");
  const b2 = useMediaQuery("(max-width:1024px)");
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ background: "#fff" }}
    >
      <Stack width={b1 ? "90%" : "75%"} m="auto" pt={12} pb={8} spacing={8}>
        <Stack width="100%" spacing={4}>
          <FooterTop b1={b1} />
          <FooterBottom b2={b2} />
        </Stack>
      </Stack>
    </Stack>
  );
};
