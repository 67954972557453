import { Close, MenuOutlined } from "@mui/icons-material";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import { NAVBAR_LINKS } from "../../constants";
import { Button } from "../Button";

export const Navbar = ({
  variant,
  setIsMenuVisible,
  isMenuVisible,
  userHasScrolled,
}) => {
  const b1 = useMediaQuery("(max-width:1024px)");
  const b2 = useMediaQuery("(max-width:600px)");

  const { pathname } = useLocation();

  return variant === "light" ? (
    <Stack
      height="80px"
      width="100vw"
      bgcolor="white.main"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "fixed",
        top: 0,
        zIndex: 99,
        animation: "slideIn ease 0.3s",
        boxShadow:
          isMenuVisible || !userHasScrolled
            ? ""
            : "rgb(0 0 0 / 10%) 0px 30px 60px -10px",
      }}
    >
      <Stack
        width={b2 ? "90%" : "80%"}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Link to="/">
          <img src="/images/logo-black.svg" alt="" height="56px" />
        </Link>
        {!b1 && (
          <Stack direction="row" spacing={6}>
            {NAVBAR_LINKS.map(({ item, url }, idx) =>
              url ? (
                <Box onClick={() => window.open(url)} key={idx}>
                  <Typography
                    variant="p"
                    color="grey.main"
                    sx={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      "&:hover": { color: "black.main" },
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              ) : (
                <Link
                  to={"/" + item.replaceAll(" ", "-").toLowerCase()}
                  style={{ textDecoration: "none" }}
                  key={idx}
                >
                  <Typography
                    variant="p"
                    color={
                      pathname === "/" + item.replaceAll(" ", "-").toLowerCase()
                        ? "black.main"
                        : "grey.main"
                    }
                    sx={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      "&:hover": { color: "black.main" },
                    }}
                  >
                    {item}
                  </Typography>
                </Link>
              )
            )}
          </Stack>
        )}
        <Stack spacing={2} direction="row">
          {!b2 && (
            <Link to="/contact-us" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                color="black"
                sx={{ height: "48px !important" }}
              >
                Contact us
              </Button>
            </Link>
          )}
          {(b1 || b2) && (
            <IconButton
              onClick={() => setIsMenuVisible(!isMenuVisible)}
              sx={{ color: "black.main", width: "48px", height: "48px" }}
            >
              {!isMenuVisible ? <MenuOutlined /> : <Close />}
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Stack
      height="80px"
      width="100vw"
      bgcolor="transparent"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        width={b2 ? "90%" : "80%"}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Link to="/">
          <img src="/images/logo-white.svg" alt="" height="56px" />
        </Link>
        {!b1 && (
          <Stack direction="row" spacing={6}>
            {NAVBAR_LINKS.map(({ item, url }, idx) =>
              url ? (
                <Box onClick={() => window.open(url)} key={idx}>
                  <Typography
                    variant="p"
                    color="#ffffff90"
                    sx={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      "&:hover": { color: "white.main" },
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              ) : (
                <Link
                  to={"/" + item.replaceAll(" ", "-").toLowerCase()}
                  style={{ textDecoration: "none" }}
                  key={idx}
                >
                  <Typography
                    variant="p"
                    color="#ffffff90"
                    sx={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      "&:hover": { color: "white.main" },
                    }}
                  >
                    {item}
                  </Typography>
                </Link>
              )
            )}
          </Stack>
        )}
        <Stack spacing={2} direction="row">
          {!b2 && (
            <Link to="/contact-us" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                color="white"
                sx={{ height: "48px !important" }}
              >
                Contact us
              </Button>
            </Link>
          )}
          {(b1 || b2) && (
            <IconButton
              onClick={() => setIsMenuVisible(!isMenuVisible)}
              sx={{ color: "white.main", width: "48px", height: "48px" }}
            >
              <MenuOutlined />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
