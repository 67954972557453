import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Button } from "../../components/Button";

export const CTABanner = () => {
  const b1 = useMediaQuery("(max-width:600px)");

  return (
    <Stack
      justifyContent="center"
      alignItems={b1 ? "center" : "left"}
      bgcolor="black.main"
      width="100%"
      py={10}
      spacing={4}
      direction={b1 ? "column" : "row"}
    >
      <Stack width="65%">
        <Stack spacing={2} sx={{ textAlign: b1 ? "center" : "left" }}>
          <Typography variant="h3" color="white.main">
            Experience true Goan Hospitality
          </Typography>
          <Typography variant="h6" color="grey.main">
            Don’t miss out! Book a room now
          </Typography>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        width="fit-content"
        color="white"
        sx={{ alignSelf: "center" }}
      >
        Book Now
      </Button>
    </Stack>
  );
};
