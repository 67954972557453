import { Menu } from "../components/Menu";
import { Outlet, useLocation } from "react-router-dom";
import { Navbar } from "../components/Navbar";

const Layout = ({ isMenuVisible, setIsMenuVisible, userHasScrolled }) => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname !== "/" ? (
        <Navbar
          variant="light"
          isMenuVisible={isMenuVisible}
          setIsMenuVisible={setIsMenuVisible}
          userHasScrolled={userHasScrolled}
        />
      ) : userHasScrolled || isMenuVisible ? (
        <Navbar
          variant="light"
          isMenuVisible={isMenuVisible}
          setIsMenuVisible={setIsMenuVisible}
          userHasScrolled={userHasScrolled}
        />
      ) : (
        ""
      )}
      {isMenuVisible && <Menu setIsMenuVisible={setIsMenuVisible} />}
      <Outlet />
    </>
  );
};

export default Layout;
