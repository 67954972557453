import ButtonMaterial from "@mui/material/Button";
import styled from "styled-components";

export const Button = styled(ButtonMaterial)`
  border-radius: 100px !important;
  text-transform: none !important;
  box-shadow: none !important;
  height: 56px !important;
  width: 156px !important;

  &:hover {
    box-shadow: none;
  }
`;
