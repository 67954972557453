import { Stack, Typography } from "@mui/material";
import { ViewStream, CheckCircle } from "@mui/icons-material";
import { MAIN1_CONTENT } from "../../../constants";
import { Box } from "@mui/system";

export const Content = ({ b1 }) => {
  return (
    <Stack spacing={2} ml={!b1 ? 8 : 0}>
      <div data-aos="zoom-in-right" data-aos-delay="0" data-aos-duration="700">
        <Stack
          justifyContent="center"
          alignItems="center"
          width="48px"
          height="48px"
          sx={{ border: "1px solid black", borderRadius: "10px" }}
        >
          <ViewStream />
        </Stack>
      </div>
      <Typography variant="h3" color="black.main">
        What we offer
      </Typography>
      {MAIN1_CONTENT.map((elem, idx) => (
        <div
          key={idx}
          data-aos="fade-left"
          data-aos-duration="400"
          data-aos-delay={idx * 300}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Box color="#E16B28">
              <CheckCircle />
            </Box>
            <Typography variant="p" color="grey.main">
              {elem}
            </Typography>
          </Stack>
        </div>
      ))}
    </Stack>
  );
};
