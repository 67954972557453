import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { AROUND_US } from "../constants";
import { CTABanner } from "../sections/CTABanner";
import { Footer } from "../sections/Footer";

const AroundUs = () => {
  const b1 = useMediaQuery("(max-width:1024px)");

  return (
    <>
      <Stack pt={10} width={b1 ? "90%" : "75%"} m="auto" my={6}>
        <Stack spacing={4}>
          <Stack spacing={2} width={b1 ? "100%" : "75%"}>
            <div data-aos="fade-up" data-aos-delay="0">
              <Typography
                variant="p"
                color="primary.main"
                sx={{ textTransform: "uppercase" }}
              >
                Around us
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="100">
              <Typography variant={b1 ? "h4" : "h3"} sx={{ fontWeight: 500 }}>
                {AROUND_US.title}
              </Typography>
            </div>
            <div data-aos="fade-up" data-aos-delay="200">
              <Typography
                variant="p"
                color="grey.main"
                sx={{ fontWeight: 500 }}
              >
                Goa - India’s paradise on Earth - is the place you want to be
                ….to unwind and enjoy the sand and surf. Whether you’re pining
                for a few days of respite in the midst of exploring the madness
                or craving for a two-week relaxed, beach holiday, ‘Souza Lopes
                Comforts’ offers just that! The land that this luxurious hotel
                is erected upon including the open plot in front of this edifice
                was once lush paddy fields belonging to Ana Rofina Souza. A
                century earlier, her husband purchased this agricultural holding
                for his better half and their darling princess, Flory. Flory and
                her troupe of 6 tilled the fields until around 25 years ago when
                she gladly acquiesced to the request of the Government of Goa
                and parted with a part of her land toward the development of the
                Baga Beach – an iconic feature of the Goan landscape.
              </Typography>
            </div>
          </Stack>
          {AROUND_US.main.map(({ title, desc, image }, idx) => (
            <Grid
              container
              alignItems={b1 ? "start" : "center"}
              direction={b1 ? "column" : idx % 2 === 0 ? "row" : "row-reverse"}
              gap={b1 ? 4 : 8}
              flexWrap="nowrap"
              py={b1 ? 2 : "calc(4vh + 40px)"}
              key={idx}
            >
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <div data-aos="fade-up" data-aos-delay="100">
                    <Typography
                      variant="h3"
                      color="black.main"
                      fontWeight={500}
                    >
                      {title}
                    </Typography>
                  </div>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Typography variant="p" color="grey.main">
                      {desc}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ width: "100%" }}>
                <div data-aos="zoom-in" data-aos-delay="0">
                  <img
                    src={image}
                    className="highlight-image"
                    width="100%"
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Stack>

      <CTABanner />
      <Footer />
    </>
  );
};

export default AroundUs;
