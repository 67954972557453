import { CTABanner } from "../sections/CTABanner";
import { Footer } from "../sections/Footer";
import { Landing } from "../sections/Landing";
import { Main1 } from "../sections/Main1";
import { Main2 } from "../sections/Main2";
import { Main3 } from "../sections/Main3";
import { Quote } from "../sections/Quote";

const Home = ({ isMenuVisible, setIsMenuVisible }) => {
  return (
    <>
      <Landing
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
      <Quote />
      <Main1 />
      <Main2 />
      <Main3 />
      <CTABanner />
      <Footer />
    </>
  );
};

export default Home;
