import { FmdGood } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export const Content = () => {
  return (
    <Stack spacing={2}>
      <div data-aos="zoom-in-right" data-aos-delay="0" data-aos-duration="700">
        <Stack
          justifyContent="center"
          alignItems="center"
          width="48px"
          height="48px"
          sx={{ border: "1px solid black", borderRadius: "10px" }}
        >
          <FmdGood />
        </Stack>
      </div>

      <div data-aos="fade-up" data-aos-delay="100">
        <Typography variant="h3" color="black.main">
          Location Is King
        </Typography>
      </div>
      <div data-aos="fade-up" data-aos-delay="200">
        <Typography variant="p" color="grey.main">
          The Souza Lopes Comforts is situated in the hot & happening holiday
          Baga beach area of North Goa. In every holiday destination, location
          is always king. In Goa, it’s the North that is involved in active
          tourism. Rest assured, your hotel room in Goa will be closest to all
          the beaches and tourist attractions. Any holiday in Goa is not
          complete, if you are not staying on the Baga – Calangute beach stretch
          of Goa.
        </Typography>
      </div>
    </Stack>
  );
};
